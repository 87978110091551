<template>
  <div>
    <v-app id="inspire">
      <v-app-bar app color="white" elevation="1">
        <v-container fluid class="py-0 fill-height">
          <v-img
            class="hidden-sm-and-down"
            max-width="150px"
            width="10"
            src="/logo.png"
          />
          <h3 class="text-uppercase">Masiya Sales</h3>
          <v-spacer></v-spacer>
          <v-menu
            v-model="menu"
            :close-on-content-click="false"
            :nudge-width="200"
            offset-x
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on" size="50" color="grey">
                mdi-account-circle
              </v-icon>
            </template>

            <v-card>
              <v-list>
                <v-list-item>
                  <v-list-item-avatar>
                    <v-icon size="50"> mdi-account-circle </v-icon>
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title
                      v-if="$store.state.user"
                      class="text-h6"
                      >{{
                        $store.state.user.fullname[0].toUpperCase() +
                        $store.state.user.fullname.substring(1)
                      }}</v-list-item-title
                    >
                    <v-list-item-subtitle v-if="$store.state.user">{{
                      $store.state.user.role[0].toUpperCase() +
                      $store.state.user.role.substring(1)
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-btn
                    @click="$router.push({ name: 'change-password' })"
                    block
                    depressed
                  >
                    Change Password
                  </v-btn>
                </v-list-item>
              </v-list>

              <v-divider />

              <v-list>
                <v-list-item>
                  <v-btn
                    block
                    @click="logoutDialog = true"
                    depressed
                    dark
                    color="red"
                  >
                    <v-icon>mdi-power</v-icon>
                    Logout
                  </v-btn>
                </v-list-item>
              </v-list>

              <v-card-actions>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-menu>
        </v-container>
      </v-app-bar>
      <v-main class="pt-5 grey lighten-3 px-5">
        <v-container fluid>
          <v-row>
            <v-col cols="12">
              <v-sheet class="pa-5" min-height="70vh" rounded="lg">
                <v-tabs
                  v-if="$store.state.user.role !== 'supervisor'"
                  v-model="tab"
                  show-arrows
                  grow
                  class="mb-5"
                >
                  <v-tab v-if="$store.state.user.role !== 'admin'"
                    >Opportunity Registration Form</v-tab
                  >
                  <v-tab v-if="$store.state.user.role !== 'admin'"
                    >List of Opportunities</v-tab
                  >
                  <v-tab v-if="$store.state.user.role === 'admin'">Users</v-tab>
                </v-tabs>
                <AddOpportunities
                  v-show="
                    $store.state.user.role !== 'supervisor' &&
                    $store.state.user.role !== 'admin' &&
                    tab === 0
                  "
                />
                <List
                  v-show="
                    ($store.state.user.role !== 'supervisor' &&
                      $store.state.user.role !== 'admin' &&
                      tab === 1) ||
                    ($store.state.user.role === 'supervisor' &&
                      $store.state.user.role !== 'admin')
                  "
                />
                <Admins v-show="$store.state.user.role === 'admin'" />
              </v-sheet>
            </v-col>
          </v-row>
        </v-container>
      </v-main>
    </v-app>
    <v-dialog v-model="logoutDialog" width="500">
      <v-card>
        <v-card-title class="text-h5"> Are you sure?</v-card-title>
        <v-card-text> You will logout from the account</v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="primary" text @click="logoutDialog = false">
            Close
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="logout"> I accept</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import AddOpportunities from "@/views/AddOpportunities";
import List from "@/views/List";
import Admins from "@/views/Admins";
export default {
  name: "Dashboard",
  components: {
    List,
    AddOpportunities,
    Admins,
  },
  data: () => ({
    tab: 0,
    logoutDialog: false,
    menu: false,
  }),
  watch: {},
  mounted() {
    this.checkAccess();
  },
  methods: {
    logout() {
      this.logoutDialog = false;
      this.$store.commit("SetUser", null);
      this.$store.commit("SetAccess", null);
      this.$router.push({ name: "Login" });
    },
    checkAccess() {
      this.$http({
        method: this.$store.getters["auth/tokenCheck"].method,
        url: this.$store.getters["auth/tokenCheck"].url,
        headers: this.$store.getters["auth/tokenCheck"].headers,
      }).catch(() => {
        this.logout();
      });
    },
  },
};
</script>

<style scoped></style>
