<template>
  <div>
    <h2 class="mb-5">List of Opportunities</h2>
    <v-progress-linear indeterminate v-if="loading"></v-progress-linear>
    <v-row>
      <v-col v-for="(item, key) in list" :key="key" cols="12" md="12">
        <v-card outlined class="pa-5 child">
          <span v-if="$store.state.user.role === 'supervisor'">
            <v-chip
              v-if="item.account_approved === true"
              color="success"
              class="float-right"
              >You approved this form</v-chip
            >
            <v-chip
              v-else-if="item.account_approved === false"
              color="red"
              dark
              class="float-right"
              >You rejected this form</v-chip
            >
          </span>
          <h4>Date: {{ item.op_date }}</h4>
          <h4 class="mt-4">
            Opportunity Name (RFP/Q Name): {{ item.op_label }}
          </h4>
          <h4>Added By: {{ item.added_by }}</h4>
          <h4 class="mt-1">
            Status: <v-chip label>{{ humanize(item.op_status) }}</v-chip>
          </h4>
          <v-simple-table dense class="mt-5" v-if="item.approvals.length >= 0">
            <template v-slot:default>
              <thead>
                <tr>
                  <td class="text-center">Email</td>
                  <td class="text-center">Approved/Rejected</td>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(ap, akey) in item.approvals" :key="akey">
                  <td class="text-center">
                    <span v-if="ap.email"
                      ><b>{{ ap.email }}</b></span
                    >
                    <span v-else> None</span>
                  </td>
                  <td class="text-center">
                    <v-chip small dark color="green" v-if="ap.approved === true"
                      ><b>Approved</b></v-chip
                    >
                    <v-chip
                      small
                      dark
                      color="red"
                      v-else-if="ap.approved === false"
                      ><b>Rejected</b></v-chip
                    >
                    <v-chip small v-else>Not decided yet</v-chip>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <br />
          <v-row>
            <v-col cols="12" md="6" class="mx-auto">
              <v-card
                color="#ededed"
                outlined
                class="pa-3"
                v-if="item.sub_lead"
              >
                <h2>Sub-lead</h2>
                <v-divider />
                <h4>Name: {{ item.sub_lead.name }}</h4>
                <h4>Email: {{ item.sub_lead.email }}</h4>
                <h4>Percentage: %{{ item.sub_lead.percentage }}</h4>
              </v-card>
            </v-col>
          </v-row>
          <div class="text-center">
            <v-btn
              v-if="
                $store.state.user.role === 'supervisor' &&
                item.account_approved === null &&
                (!selected_oppor_id || selected_oppor_id !== item.op_id)
              "
              color="primary"
              depressed
              class="mx-auto"
              @click="takeAction(item.op_id)"
              >Take An Action</v-btn
            >
          </div>
          <v-alert
            v-if="
              selected_oppor_id === item.op_id &&
              $store.state.user.role === 'supervisor'
            "
            colored-border
            color="primary"
            class="text-center"
          >
            <v-btn
              class="mr-1 mb-1"
              @click="handleActionDialog('approve')"
              color="success"
              >Approve</v-btn
            >
            <v-btn
              class="mr-1 mb-1"
              @click="handleActionDialog('reject')"
              dark
              color="red"
              >Reject</v-btn
            >
            <v-btn @click="cancelAction" class="mr-1 mb-1" dark color="black"
              >Cancel</v-btn
            >
          </v-alert>
        </v-card>
      </v-col>
    </v-row>
    <div v-if="list.length > 0" class="text-center my-5">
      <v-pagination v-model="page" :length="total_pages"></v-pagination>
    </div>
    <div v-if="list.length === 0 && !loading">
      <v-img max-width="350" class="mx-auto mt-16" src="/no_data_found.png" />
      <h2 style="color: #868686" class="text-center my-10">No data found</h2>
    </div>
    <v-dialog v-model="actionDialog" width="500">
      <v-card>
        <v-card-title class="text-h5">Please confirm</v-card-title>
        <v-card-text v-if="selected_action === 'approve'"
          >You will <b>approve</b> the form</v-card-text
        >
        <v-card-text v-else>You will <b>reject</b> the form</v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="primary" text @click="actionDialog = false">
            Close
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="makeAction">Confirm</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "List",
  data: () => ({
    list: [],
    selected_oppor_id: null,
    selected_action: null,
    actionDialog: false,
    page: 1,
    total_pages: 1,
    loading: false,
  }),
  watch: {
    page() {
      this.getList();
    },
  },
  mounted() {
    this.getList();
  },
  methods: {
    humanize(str) {
      let i,
        frags = str.split("_");
      for (i = 0; i < frags.length; i++) {
        frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
      }
      return frags.join(" ");
    },
    handleActionDialog(action) {
      this.selected_action = action;
      this.actionDialog = true;
    },
    makeAction() {
      this.loading = true;
      this.$http({
        method: this.$store.getters["opportunities/directResponse"].method,
        url: `${this.$store.getters["opportunities/directResponse"].url}/${this.selected_action}/${this.selected_oppor_id}`,
        headers: this.$store.getters["opportunities/directResponse"].headers,
      })
        .then(() => {
          this.cancelAction();
          this.getList();
        })
        .catch((err) => {
          if (!err.response.data) {
            alert("Something is wrong");
            return null;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    cancelAction() {
      this.selected_oppor_id = null;
      this.selected_action = null;
      this.actionDialog = null;
    },
    takeAction(id) {
      this.selected_oppor_id = id;
    },
    getList() {
      this.loading = true;
      this.$http({
        method: this.$store.getters["opportunities/list"].method,
        url: `${this.$store.getters["opportunities/list"].url}?page=${
          this.page - 1
        }`,
        headers: this.$store.getters["opportunities/list"].headers,
      })
        .then((response) => {
          this.list = response.data.opportunities;
          this.total_pages = response.data.total_pages;
        })
        .catch((err) => {
          if (!err.response.data) {
            alert("Something is wrong");
            return null;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped></style>
