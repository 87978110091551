<template>
  <div>
    <div class="text-center my-8">
      <v-btn color="primary" @click="handleCreateDialog" depressed
        >Add new user</v-btn
      >
    </div>
    <v-progress-linear v-if="loading" indeterminate />
    <v-simple-table v-else dense>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">ID</th>
            <th class="text-left">Name</th>
            <th class="text-left">Email</th>
            <th class="text-left">Role</th>
            <th class="text-left">Enabled</th>
            <th class="text-left">Designation</th>
            <th class="text-left">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="user in users" :key="user.id">
            <td>{{ user.id }}</td>
            <td>{{ user.name }}</td>
            <td>{{ user.email }}</td>
            <td>{{ user.app_role }}</td>
            <td>
              <span v-if="user.is_enabled">Yes</span><span v-else>No</span>
            </td>
            <td>{{ user.designation }}</td>
            <td>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    @click.prevent="handleEditDialog(user)"
                    v-bind="attrs"
                    v-on="on"
                    color="primary"
                    depressed
                    x-small
                    fab
                    class="pa-2"
                    ><v-icon>mdi-pencil</v-icon></v-btn
                  >
                </template>
                <span>Edit</span>
              </v-tooltip>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <v-dialog v-model="createDialog" max-width="800px">
      <v-card class="pa-2">
        <v-card-title>
          <span class="text-h5">Create new user</span>
        </v-card-title>
        <v-form
          ref="form"
          @submit.prevent="create"
          id="check-login-form"
          class="my-5 px-8"
        >
          <v-text-field
            v-model="name"
            label="Name"
            :rules="rules.name"
            :error="errors && errors.field_name === 'name'"
            :error-messages="
              errors && errors.field_name === 'name' ? errors.msg : ''
            "
            type="text"
          ></v-text-field>
          <v-text-field
            v-model="password"
            :rules="rules.password"
            label="Password"
            :error="errors && errors.field_name === 'password'"
            :error-messages="
              errors && errors.field_name === 'password' ? errors.msg : ''
            "
            type="password"
          ></v-text-field>
          <v-text-field
            v-model="email"
            label="Email"
            :rules="rules.email"
            :error="errors && errors.field_name === 'email'"
            :error-messages="
              errors && errors.field_name === 'email' ? errors.msg : ''
            "
            type="text"
          ></v-text-field>
          <v-select
            :rules="rules.role"
            :items="roles"
            v-model="app_role"
            label="Role"
          ></v-select>
          <v-text-field
            v-model="designation"
            label="Designation"
            :rules="rules.designation"
            :error="errors && errors.field_name === 'designation'"
            :error-messages="
              errors && errors.field_name === 'designation' ? errors.msg : ''
            "
            type="text"
          ></v-text-field>
          <div>
            <v-switch
              v-model="enabled"
              :label="`Enabled: ${enabled ? 'Yes' : 'No'}`"
            ></v-switch>
          </div>
        </v-form>
        <v-card-actions>
          <v-btn text @click="createDialog = false"> Close </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="cloading"
            :loading="cloading"
            color="darken-1"
            text
            @click.prevent="create"
          >
            Add
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="editDialog" max-width="800px">
      <v-card class="pa-2">
        <v-card-title>
          <span class="text-h5">Update user</span>
        </v-card-title>
        <v-form
          ref="editForm"
          @submit.prevent="create"
          id="check-edit-form"
          class="my-5 px-8"
        >
          <v-text-field
            v-model="name"
            label="Name"
            :rules="rules.name"
            :error="errors && errors.field_name === 'name'"
            :error-messages="
              errors && errors.field_name === 'name' ? errors.msg : ''
            "
            type="text"
          ></v-text-field>
          <v-text-field
            v-model="password"
            :rules="rules.edit_password"
            label="Password"
            :error="errors && errors.field_name === 'password'"
            :error-messages="
              errors && errors.field_name === 'password' ? errors.msg : ''
            "
            type="password"
          ></v-text-field>
          <v-text-field
            v-model="email"
            label="Email"
            :rules="rules.email"
            :error="errors && errors.field_name === 'email'"
            :error-messages="
              errors && errors.field_name === 'email' ? errors.msg : ''
            "
            type="text"
          ></v-text-field>
          <v-select
            :rules="rules.role"
            :items="roles"
            v-model="app_role"
            label="Role"
          ></v-select>
          <v-text-field
            v-model="designation"
            label="Designation"
            :rules="rules.designation"
            :error="errors && errors.field_name === 'designation'"
            :error-messages="
              errors && errors.field_name === 'designation' ? errors.msg : ''
            "
            type="text"
          ></v-text-field>
          <div>
            <v-switch
              v-model="enabled"
              :label="`Enabled: ${enabled ? 'Yes' : 'No'}`"
            ></v-switch>
          </div>
        </v-form>
        <v-card-actions>
          <v-btn text @click="editDialog = false"> Close </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="cloading"
            :loading="cloading"
            color="darken-1"
            text
            @click.prevent="update"
          >
            Edit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  name: "Admins",
  computed: {
    rules() {
      return {
        name: [(v) => !!v || "The field is required"],
        password: [
          (v) => !!v || "The field is required",
          (v) =>
            (v && v.length >= 8) ||
            "This field must have at least 8 characters",
        ],
        edit_password: [
          (v) =>
            (v && v.length >= 8) ||
            !v ||
            "This field must have at least 8 characters",
        ],
        email: [
          (v) => !!v || "The field is required",
          (v) =>
            !v ||
            /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
            "E-mail must be valid",
        ],
        role: [(v) => !!v || "The field is required"],
        enabled: [(v) => !!v || "The field is required"],
        designation: [],
      };
    },
  },
  data: () => {
    return {
      users: [],
      roles: ["entry", "supervisor", "admin"],
      loading: false,
      cloading: false,
      createDialog: false,
      editDialog: false,
      errors: null,
      id: null,
      name: null,
      password: null,
      email: null,
      app_role: null,
      enabled: false,
      designation: null,
    };
  },
  mounted() {
    this.getUsers();
  },
  methods: {
    handleCreateDialog() {
      this.closeForm();
      this.createDialog = true;
    },
    handleEditDialog(user) {
      this.closeForm();
      this.editDialog = true;
      this.id = user.id;
      this.name = user.name;
      this.email = user.email;
      this.app_role = user.app_role;
      this.enabled = user.is_enabled;
      this.designation = user.designation;
    },
    closeForm() {
      this.editDialog = false;
      this.createDialog = false;
      this.name = null;
      this.password = null;
      this.email = null;
      this.app_role = null;
      this.enabled = false;
      this.designation = null;
      this.getUsers();
    },
    getUsers() {
      this.loading = true;
      this.errors = null;
      this.$http({
        method: this.$store.getters["admin/list"].method,
        url: this.$store.getters["admin/list"].url,
        headers: this.$store.getters["admin/list"].headers,
      })
        .then((response) => {
          this.users = response.data.employees;
        })
        .catch((err) => {
          if (!err.response.data) {
            alert("Something is wrong");
            return null;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    create() {
      this.cloading = true;
      this.form_valid = this.$refs.form.validate();
      if (!this.form_valid) {
        return null;
      }
      let requestBody = {
        name: this.name,
        password: this.password,
        email: this.email,
        app_role: this.app_role,
        enabled: this.enabled,
        designation: this.designation,
      };
      this.errors = null;
      this.$http({
        method: this.$store.getters["admin/add"].method,
        url: this.$store.getters["admin/add"].url,
        headers: this.$store.getters["admin/add"].headers,
        data: requestBody,
      })
        .then(() => {
          this.closeForm();
        })
        .catch((err) => {
          if (!err.response.data) {
            alert("Something is wrong");
            return null;
          }
          let detail = err.response.data.detail[0];
          this.errors = {
            field_name: detail["loc"][1],
            msg: detail.msg,
          };
        })
        .finally(() => {
          this.cloading = false;
        });
    },
    update() {
      this.cloading = true;
      this.form_valid = this.$refs.editForm.validate();
      if (!this.form_valid) {
        return null;
      }
      let requestBody = {
        name: this.name,
        password: this.password,
        email: this.email,
        app_role: this.app_role,
        enabled: this.enabled,
        designation: this.designation,
      };
      this.errors = null;
      this.$http({
        method: this.$store.getters["admin/edit"].method,
        url: `${this.$store.getters["admin/edit"].url}/${this.id}`,
        headers: this.$store.getters["admin/edit"].headers,
        data: requestBody,
      })
        .then(() => {
          this.closeForm();
        })
        .catch((err) => {
          if (!err.response.data) {
            alert("Something is wrong");
            return null;
          }
          let detail = err.response.data.detail[0];
          this.errors = {
            field_name: detail["loc"][1],
            msg: detail.msg,
          };
        })
        .finally(() => {
          this.cloading = false;
        });
    },
  },
};
</script>

<style scoped></style>
