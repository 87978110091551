<template>
  <div>
    <h2>Opportunity Registration Form</h2>
    <v-form
      v-model="form_valid"
      id="check-form"
      ref="form"
      lazy-validation
      @submit.prevent="submit"
      class="pa-5"
    >
      <v-row>
        <v-col cols="12" md="6">
          <v-menu
            ref="menu"
            v-model="op_date_menu"
            :close-on-content-click="false"
            :return-value.sync="op_date"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="op_date"
                label="Date"
                :rules="rules.date"
                readonly
                :error="errors && errors.field_name === 'op_date'"
                :error-messages="
                  errors && errors.field_name === 'op_date' ? errors.msg : ''
                "
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="op_date" no-title scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="op_date_menu = false">
                Cancel
              </v-btn>
              <v-btn text color="primary" @click="$refs.menu.save(op_date)">
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="op_label"
            :rules="rules.op_label"
            :error="errors && errors.field_name === 'op_label'"
            :error-messages="
              errors && errors.field_name === 'op_label' ? errors.msg : ''
            "
            label="Opportunity Name (RFP/Q Name)"
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="op_customer"
            :error="errors && errors.field_name === 'op_customer'"
            :error-messages="
              errors && errors.field_name === 'op_customer' ? errors.msg : ''
            "
            :rules="rules.op_customer"
            label="Customer"
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            :error="errors && errors.field_name === 'op_supplier'"
            :error-messages="
              errors && errors.field_name === 'op_supplier' ? errors.msg : ''
            "
            v-model="op_supplier"
            :rules="rules.op_supplier"
            label="Supplier"
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            :error="errors && errors.field_name === 'op_distributor'"
            :error-messages="
              errors && errors.field_name === 'op_distributor' ? errors.msg : ''
            "
            v-model="op_distributor"
            :rules="rules.op_distributor"
            label="Distributor"
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-textarea
            :error="errors && errors.field_name === 'op_distributor'"
            :error-messages="
              errors && errors.field_name === 'op_distributor' ? errors.msg : ''
            "
            v-model="op_description"
            label="Brief Description"
            :rules="rules.op_description"
          ></v-textarea>
        </v-col>
        <v-col cols="12">
          <div class="switch-center">
            <v-switch
              v-model="op_sub_lead"
              :label="!op_sub_lead ? 'Lead' : 'Sub-lead'"
            ></v-switch>
          </div>
        </v-col>
        <v-col v-if="op_sub_lead" cols="12" md="11" class="mx-auto">
          <v-card outlined class="pa-10 rounded">
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="op_sub_lead_name"
                  :error="errors && errors.field_name === 'op_sub_lead_name'"
                  :error-messages="
                    errors && errors.field_name === 'op_sub_lead_name'
                      ? errors.msg
                      : ''
                  "
                  :rules="rules.op_sub_lead_name"
                  label="Sub-lead employee name"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="op_sub_lead_percentage"
                  :error="
                    errors && errors.field_name === 'op_sub_lead_percentage'
                  "
                  :error-messages="
                    errors && errors.field_name === 'op_sub_lead_percentage'
                      ? errors.msg
                      : ''
                  "
                  :rules="rules.op_sub_lead_percentage"
                  append-icon="mdi-percent"
                  label="Sub-lead percentage"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="op_sub_lead_email"
                  :rules="rules.op_sub_lead_email"
                  :error="errors && errors.field_name === 'op_sub_lead_email'"
                  :error-messages="
                    errors && errors.field_name === 'op_sub_lead_email'
                      ? errors.msg
                      : ''
                  "
                  label="Sub-lead email"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col cols="12" class="text-center">
          <v-btn
            type="submit"
            :disabled="loading"
            :loading="loading"
            :dark="!loading"
            form="check-form"
            class="px-10 my-5"
            color="orange"
            large
          >
            <v-icon>mdi-send</v-icon>
            <span class="px-1">Submit</span>
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
    <v-dialog persistent v-model="announceDialog" width="500">
      <v-card class="pa-5 text-center">
        <div class="text-center">
          <v-icon size="90" class="text-center" color="green"
            >mdi-check-decagram
          </v-icon>
        </div>
        <h1>Success</h1>
        <v-card-text>Your submission has been sent</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click.prevent="closeForm"> Ok</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "AddOpportunities",
  computed: {
    rules() {
      return {
        employee_name: [(v) => !!v || "The field is required"],
        employee_designation: [(v) => !!v || "The field is required"],
        date: [(v) => !!v || "The field is required with format (YYYY-MM-DD)"],
        op_label: [(v) => !!v || "The field is required"],
        op_customer: [(v) => !!v || "The field is required"],
        op_supplier: [(v) => !!v || "The field is required"],
        op_distributor: [(v) => !!v || "The field is required"],
        op_description: [(v) => !!v || "The field is required"],
        op_sub_lead_name: [(v) => !!v || "The field is required"],
        op_sub_lead_email: [(v) => !!v || "The field is required"],
        op_sub_lead_percentage: [
          (v) => !!v || "The field is required",
          (v) =>
            (!isNaN(parseFloat(v)) && v >= 0 && v <= 100) ||
            "Number has to be between 0 and 100",
        ],
      };
    },
  },
  data: () => ({
    form_valid: false,
    op_date_menu: false,
    op_date: moment().format("YYYY-MM-DD"),
    op_label: null,
    op_description: null,
    op_customer: null,
    op_supplier: null,
    op_distributor: null,
    op_sub_lead: false,
    op_sub_lead_name: null,
    op_sub_lead_percentage: null,
    op_sub_lead_email: null,
    loading: false,
    errors: null,
    announceDialog: false,
  }),
  methods: {
    closeForm() {
      this.announceDialog = false;
      setTimeout(() => {
        window.location.reload();
      }, 200);
    },
    submit() {
      this.form_valid = this.$refs.form.validate();
      if (!this.form_valid) {
        return null;
      }
      let requestBody = {
        op_date: this.op_date,
        op_label: this.op_label,
        op_description: this.op_description,
        op_customer: this.op_customer,
        op_supplier: this.op_supplier,
        op_distributor: this.op_distributor,
        op_sub_lead: this.op_sub_lead,
        op_sub_lead_name: this.op_sub_lead_name,
        op_sub_lead_percentage: this.op_sub_lead_percentage,
        op_sub_lead_email: this.op_sub_lead_email,
      };
      this.loading = true;
      this.errors = null;
      this.$http({
        method: this.$store.getters["opportunities/add"].method,
        url: this.$store.getters["opportunities/add"].url,
        headers: this.$store.getters["opportunities/add"].headers,
        data: requestBody,
      })
        .then(() => {
          this.announceDialog = true;
          setTimeout(() => {
            this.closeForm();
          }, 3000);
        })
        .catch((err) => {
          if (!err.response.data) {
            alert("Something is wrong");
            return null;
          }
          let detail = err.response.data.detail[0];
          this.errors = {
            field_name: detail["loc"][1],
            msg: detail.msg,
          };
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
.switch-center {
  display: flex;
  justify-content: center;
}
</style>
